<template>
  <div class="animated fadeIn">
    <b-card>
      <h4 class="d-inline"><i class="nav-icon icon-layers"></i> Invoices</h4>

      <hr class="clearfix my-3">
      <div class="row mb-3">
        <div class="col-12 col-md-3">
          <h5>Ready To Pay / Paid</h5>
          <v-select
            class="project-select"
            v-model="type"
            label="type"
            :options="typeOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div class="col-9 col-md-3">
          <h5>Projects</h5>
          <v-select
            class="project-select"
            v-model="project"
            label="projectname"
            placeholder="Select Project"
            :options="projectOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div class="col-9 col-md-3">
          <h5>Qbyte Status</h5>
          <v-select
            class="project-select"
            v-model="qbyte_status"
            label="name"
            placeholder="Select Status"
            :options='[{ "name": "All"}, {"name": "Unprocessed"},{"name": "Failed"},{"name": "Draft"},{"name": "Submitted"},{"name": "Posted"},{"name": "Loaded"},{"name": "Processed"},{"name": "Qbyte Rejected"}]'
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div v-if="type" class="col-3">
          <h5>&nbsp;</h5>
          <button @click="loadInvoices()" class="btn btn-primary"><i class="fa fa-sync-alt"></i></button>
        </div>
      </div>


      <b-modal ok-only id="history" ref="history" :title="'Invoice #' + history.invnum" title-tag="h2" centered size="xl">
        <InvoiceHistory v-bind:history="history"></InvoiceHistory>
      </b-modal>


      <v-client-table v-on:filter="clearSelected()" id="dataTable" class="inv-status-table table-responsive acc-table" :columns="columns" :data="invoiceData" ref="invoicetable" :options="options" :theme="theme">
        <span slot="total" slot-scope="props">     
     {{formatPrice(props.row.total)}}  
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span v-if="props.row.quickpay_total > 0 && $moment(props.row.endtday) > $moment()">
        {{formatPrice(props.row.quickpay_total)}}
        </span>
        </div>

        <input slot="check" v-if="props.row.accountant_paid === 0" slot-scope="props" type="checkbox" :value="{'id': props.row.invoiceid, 'projectid': props.row.projectid, 'quickpay_total': props.row.quickpay_total, 'total': props.row.total, 'endtday': props.row.endtday, 'bankaccount': props.row.bankaccount, 'vcn': props.row.vcn}" class="checkedInvoices rt-checkbox-table" v-model="checkedRows">
        <div slot="inv_passed" slot-scope="props">
          <div v-if="props.row.accountant_paid === 0">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
          </div>
        </div>
        <div slot="qbyte_status" slot-scope="props">
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == -1" class="btn-sm btn-danger"><i class="fa fa-question"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 0" class="btn-sm btn-secondary"><i class="fa fa-question"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == -2" class="btn-sm btn-secondary"><i class="fa fa-question"></i>Unprocessed</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 1" class="btn-sm btn-blue"><i class="fa fa-save"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 2" class="btn-sm btn-primary"><i class="fa fa-check"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 3" class="btn-sm btn-warning"><i class="fa fa-check"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 4" class="btn-sm btn-success"><i class="fa fa-check"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize" v-if="props.row.qbyte_status.code == 5" class="btn-sm btn-success"><i class="fa fa-check"></i> {{props.row.qbyte_status.text}}</button>
          <button style="text-transform: capitalize; background-color: dimgray; color: white;" v-if="props.row.qbyte_status.code == -3" class="btn-sm btn-success"><i class="fa fa-trash"></i> {{props.row.qbyte_status.text}}</button>
        </div>
        <div slot="creation_date" slot-scope="props">{{ props.row.creation_date }}</div>
        <div slot="vfname" slot-scope="props">{{ props.row.vfname }} {{ props.row.vlname }}</div>

        <div slot="invnum" slot-scope="props">
          <a href="javascript:void(0)" @click="setTheInvoice(props.row)">{{props.row.invnum}}</a>
        </div>

        <div slot="actions" slot-scope="props">
          <button v-b-modal.history @click="sendHistory(props.row)" class="btn white btn-sm btn-secondary"><i class="fa fa-history"></i></button>
        </div>
      </v-client-table>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import VueJsonToCsv from 'vue-json-to-csv'
import InvoiceHistory from './../invoice/InvoiceHistory'
import Multiselect from 'vue-multiselect'
Vue.use(ClientTable)
export default {
  name: 'manager',
  components: {
    ClientTable,
    Datepicker,
    VueJsonToCsv,
    InvoiceHistory,
    InvoiceHistory,
    Multiselect
  },
  data () {
    return {
    
      columns: ['inv_passed', 'qbyte_status', 'invnum', 'ticketnum', 'vendor_invoice_number', 'vcn', 'vfname', 'creation_date', 'total', 'quickpay_total', 'actions'],
      today: '',
      qbyte_status: {"name": "All"},
      customTest: [],
      options: {
        initFilters:{
          'GENERIC': this.$session.get('query'),
        },
        orderBy: this.$session.get('orderBy'),
        initialPage: this.$session.get('page'),
        //initialPage: this.$session.get('page'),
        headings: {
          inv_passed: 'Status',
          invnum: 'Invoice #',
          creation_date: 'Submitted Date (DD/MM/YYYY)',
          qbyte: 'Qbyte Status',
          amount: 'Amount',
          vcn: 'Company Name',
          projectname: 'Project Name',
          vfname: 'Vendor Name',
          quickpay_total: 'Quickpay Total',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #'
        },
        oLanguage: {
          sSearch: "your-text-here"
        },
        sortable: ['inv_passed', 'invnum', 'qbyte_status', 'vcn', 'projectname', 'vfname', 'creation_date', 'total', 'quickpay_total'],
        filterable: ['invnum', 'ticketnum', 'vendor_invoice_number', 'vcn', 'creation_date', 'qbyte_status', 'vfname', 'total', 'quickpay_total'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10,
        texts:{
          filterPlaceholder: "Search By Vendor"
        },
        // pagination: {
        // 	nav: 'scroll',
        //   chunk: 40
        // },
      },
      searchContainer: true,
      cc: '',
      afe: '',
      history: [],
      invoiceData: [],
      date: '',
      tracking: '',
      vendor: '',
      type: {
        type: 'Ready To Pay',
      },
      typeOptions: [
        {
          type: 'All',
        },
        {
          type: 'Ready To Pay',
        },
        {
          type: 'Paid',
        }
      ],
      projectData: [],
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      projectid: 0,
      checkedRows: [],
      afe: '',
      cc1: '',
      cc2: '',
      cc3: '',
      invnum: '',
      date: '',
      vendor_company_name: '',
      projectidSearch: 0,
      windowWidth: 0,
      windowHeight: 0,
      labels: {
        vcn: { title: 'Company Name' },
        gst: { title: 'GST Number' },
        rcn: { title: 'Registration Number' },
        bankaccount: { title: 'Bank Account' },
        addressline1: { title: 'Address Line 1' },
        addressline2: { title: 'Address Line 2' },
        city: { title: 'City' },
        province: { title: 'Province' },
        postalcode: { title: 'Postal Code' },
        phone_num: { title: 'Phone Number' },
        invnum: { title: 'Invoice Number' },
        creation_date: { title: 'Creation Date' },
        total: { title: 'Total (Including GST)' },
        gst_total: { title: 'GST' },
        quickpay_total: { title: 'Quick Pay Total (Including GST)' },
        gst_quickpay_total: { title: 'Quick Pay GST' },
      },
      customLabel: [
        ["vcn", "Company Name"],
        ["invnum", "Invoice Number" ],
        ["creation_date", "Invoice Date"],
        ["total", "Total (Including GST)"],
        ["gst_total", "GST"],
        ["quickpay_total", "Quick Pay Total (Including GST)"],
        ["gst_quickpay_total", "Quick Pay GST"],
      ],
      customFiltered: [],
      customAfe: false,
      chequeDate: "",
      chequeNumber: "",
      comments: "",
      markedRows: [],
      allMarked:false,
      afesList: [],
      afe: [],
      serviceData: [],
      serviceList: [],
      usersName: "",
      project: null,
      projectOptions: [],
      qbyteInvoice:[]
    }
  },
  methods: {
    setTheInvoice(props){
      //this.$session.set('invoice', props)
      //let routeData = this.$router.resolve({name: 'Accountant Qbyte Invoice'});
      //window.open(routeData.href, '_blank');
      this.setInvoice(props, 0, this.$refs.invoicetable);
      this.$router.push({ name: 'Accountant Qbyte Invoice', path: '/accountant/qbyte-invoice', params: { invoice: props} })
    },
    loadServiceEstimates() {
      this.afeList = [];
      this.afe = [];
      this.serviceList = [];
      for (var i = 0; i < this.checkedRows.length; i++) {
        this.$http.get('/manager/get/invoice_services/' + this.checkedRows[i].projectid + '/' + this.checkedRows[i].id)
        .then(response => {
          this.afesList = this.afesList.concat(response.data.result);
          this.serviceList = this.serviceList.concat(response.data.result);
          this.afesList = this.lodash.uniqBy(this.afesList, 'afenum');
          this.$refs.serviceEstimates.show();
        })
        .catch(error => {
          console.log(error);
        })
      }
    },
    getServices(){
      this.serviceData = [];
      this.$axios.get('/service_estimate/get/' + this.afe.projectid + '/' + this.afe.pwcaid).then(response => {
        var result = this.lodash.uniqBy(response.data.result, 'apid');
        // const intersectServices = result.reduce((accum, role) => {
        //   const service = this.serviceList.find(({ serviceid }) => role.serviceid === serviceid);
        //   if (service) accum.push({ ...role, ...service });
        //   return accum;
        // }, []);
        var temp = [];
        for (var i = 0; i < this.serviceList.length; i++) {
          if(result.filter(x => x.serviceid == this.serviceList[i].serviceid).length > 0 && temp.filter(x=>x.serviceid == this.serviceList[i].serviceid).length == 0){
            var tempPush = result.filter(x=>x.serviceid == this.serviceList[i].serviceid);
            for (var x = 0; x < tempPush.length; x++) {
              temp.push(tempPush[x])
            }
          }
        }
        this.serviceData = temp.filter(x=>x.afenum == this.afe.afenum)
      })
    },
    clearSelected() {
      this.checkedRows = [];
    },
    selectAllInTable() {
      this.checkedRows = [];
      this.allMarked = !this.allMarked;
      if(this.allMarked){
        for (var i = 0; i < this.$refs.invoicetable.allFilteredData.length; i++) {
          this.checkedRows.push({
            "id": this.$refs.invoicetable.allFilteredData[i].invoiceid,
            "projectid": this.$refs.invoicetable.allFilteredData[i].projectid,
            "quickpay_total": this.$refs.invoicetable.allFilteredData[i].quickpay_total,
            "total": this.$refs.invoicetable.allFilteredData[i].total,
            "endtday": this.$refs.invoicetable.allFilteredData[i].endtday,
            "bankaccount": this.$refs.invoicetable.allFilteredData[i].bankaccount,
            "vcn": this.$refs.invoicetable.allFilteredData[i].vcn,
          });
        }
      }
      else {
        this.checkedRows = [];
      }
    },
    markAll(){
      this.markAllInvoices()
      .then(data => {
        this.$toasted.show('Invoices Updated Successfully', {type: 'success', duration: '3000'});
        this.loadInvoices();
        console.log(data)
      })
    },
    markAllInvoices() {
      return new Promise((resolve, reject) => {
        let data = JSON.stringify()
        var comments = this.usersName;
        if(this.comments != ""){
          comments += ": " + this.comments;
        }
        const promises = this.checkedRows.map(record => this.$http.post('/accountant/edit/invoice', {
          invoiceid: record.id,
          projectid: record.projectid,
          accountant_paid: 1,
          ac_note: comments,
          status: 1,
          check_round_date: this.chequeDate,
          check_number: this.chequeNumber
        }));
        Promise.all(promises) // creates single promise that resolves when all `promises` resolve
          .then(responses => {
            const dataArray = responses.map(response => response.data.result);
            return resolve(dataArray);
          }) // resolves with an array of response data
          .catch(reject);
      })
    },
    clearLabels() {
      this.customFiltered = [];
      this.customAfe = false;
      this.customLabel = [
        ["vcn", "Company Name"],
        ["invnum", "Invoice Number" ],
        ["creation_date", "Invoice Date"],
        ["total", "Total (Including GST)"],
        ["gst_total", "GST"],
        ["quickpay_total", "Quick Pay Total (Including GST)"],
        ["gst_quickpay_total", "Quick Pay GST"],
      ];
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getQbyteInvoice(){
      this.$http.get('/accountant/qbyte/invoices/qbyte/invoice_list')
        .then(res => {
          if(res.data.getQbyteInvoice.length > 0) {
            this.invoiceData.map((v,i) =>{
              res.data.getQbyteInvoice.map(x =>{
                if(x.invoice_id == v.invoiceid) {
                    this.invoiceData[i].total = x.total;
                    this.invoiceData[i].quickpay_total = x.quickpay_total;
                }
              })
            });
          }
        }).catch(()=> {});
    },
    loadInvoices() {
      this.today = 'Export - ' + this.$moment(new Date).format('YYYY-MM-DD');
      this.$session.set('type', this.type.type)
      this.$session.set('project', this.project)
      this.$session.set('qbyte_status', this.qbyte_status)
      this.checkedRows = [];
      this.$http.get('/accountant/qbyte/invoices')
        .then(response => {
          const that = this;
          this.invoiceData = response.data.reverse().map(function(i) {
            i.creation_date = that.$moment(i.creation_date).format("DD/MM/YYYY");
            return i;
          });
          if(this.project != null && this.project.projectname != 'All Projects') {
            this.invoiceData = this.invoiceData.filter(x=>x.projectname == this.project.projectname);
          }
          if(this.type.type === 'Ready To Pay'){
            this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 0);
          }
          if(this.type.type === 'Paid'){
            this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 1);
          }
          if(this.qbyte_status.name === 'Unprocessed'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 0 || x.qbyte_status.code == -2);
          }
          if(this.qbyte_status.name === 'Failed'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == -1);
          }
          if(this.qbyte_status.name === 'Draft'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 1);
          }
          if(this.qbyte_status.name === 'Submitted'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 2);
          }
          if(this.qbyte_status.name === 'Posted'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 3);
          }
          if(this.qbyte_status.name === 'Loaded'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 4);
          }
          if(this.qbyte_status.name === 'Processed'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == 5);
          }
          if(this.qbyte_status.name === 'Qbyte Rejected'){
            this.invoiceData = this.invoiceData.filter(x=>x.qbyte_status.code == -3);
          }
          this.$refs.invoicetable.currentPage = this.$session.get('page');
          this.getQbyteInvoice();
      })
      .catch(error => {
      });
    },
    sendHistory(history) {
      this.history = history;
    },
    getFullFiltered() {
      var filtered = this.$refs.invoicetable.allFilteredData;
      return new Promise((resolve, reject) => {
        const promises = this.$refs.invoicetable.allFilteredData.map(record => this.$http.get('/manager/get/invoice_services/' + record.projectid + '/' + record.invoiceid));
        Promise.all(promises) // creates single promise that resolves when all `promises` resolve
          .then(responses => {
            const dataArray = responses.map(response => response.data.result);
            return resolve(dataArray);
          }) // resolves with an array of response data
          .catch(reject);
      })
    },
    seperateLines() {
      if(this.customAfe){
        this.customLabel = [
          ["vcn", "Company Name"],
          ["invnum", "Invoice Number" ],
          ["creation_date", "Invoice Date"],
          ["total", "Total (Including GST)"],
          ["gst_total", "GST"],
          ["quickpay_total", "Quick Pay Total (Including GST)"],
          ["gst_quickpay_total", "Quick Pay GST"],
        ];
      }
      if(!this.customAfe){
        this.customFiltered = [];
        const that = this;
        const filtered = this.sendFiltered();
        this.getFullFiltered()
        .then(data => {
          this.customLabel.splice(1, 0, ["afe", "AFE Number"], ["percentage", "AFE Percentage"], ["cc1", "Cost Code 1"], ["cc2", "Cost Code 2"], ["cc3", "Cost Code 3"]);
          for (var i = 0; i < data.length; i++) {
            var afes = data[i]
            afes.forEach(function(part, index) {
              var find = filtered.findIndex(h => h.invoiceid == this[index].invoiceid)
              var filter = filtered.filter(o => o.invoiceid === this[index].invoiceid)
              var cc3 = "";
              if(!this[index].ccthree_code.includes('---')){
                cc3 = this[index].ccthree_code;
              }
              var tempFiltered = {
                invoiceid: filter[0].invoiceid,
                vid: filter[0].vid,
                companyid: filter[0].companyid,
                projectid: filter[0].projectid,
                invnum: filter[0].invnum,
                inv_passed: filter[0].inv_passed,
                tax_percentage: filter[0].tax_percentage,
                discount: filter[0].discount,
                vendor_note: filter[0].vendor_note,
                wss_note: filter[0].wss_note,
                wss_mdate: filter[0].wss_mdate,
                wssid: filter[0].wssid,
                wss_stat: filter[0].wss_stat,
                wts_note: filter[0].wts_note,
                wts_mdate: filter[0].wts_mdate,
                wtsid: filter[0].wtsid,
                wts_stat: filter[0].wts_stat,
                creation_date: filter[0].creation_date,
                vidcode: filter[0].vidcode,
                bankaccount: filter[0].bankaccount,
                vfname: filter[0].vfname,
                vlname: filter[0].vlname,
                gst: filter[0].gst,
                rcn: filter[0].rcn,
                vcn: filter[0].vcn,
                wssfname: filter[0].wssfname,
                wsslname: filter[0].wsslname,
                wssphone_num: filter[0].wssphone_num,
                wtsfname: filter[0].wtsfname,
                wtslname: filter[0].wtslname,
                wts_phone: filter[0].wts_phone,
                wts_email: filter[0].wts_email,
                mid1: filter[0].mid1,
                mid2: filter[0].mid2,
                mid3: filter[0].mid3,
                mid4: filter[0].mid4,
                mid5: filter[0].mid5,
                mgr1_mdate: filter[0].mgr1_mdate,
                mgr2_mdate: filter[0].mgr2_mdate,
                mgr3_mdate: filter[0].mgr3_mdate,
                mgr4_mdate: filter[0].mgr4_mdate,
                mgr5_mdate: filter[0].mgr5_mdate,
                mgr1_note: filter[0].mgr1_note,
                mgr2_note: filter[0].mgr2_note,
                mgr3_note: filter[0].mgr3_note,
                mgr4_note: filter[0].mgr4_note,
                mgr5_note: filter[0].mgr5_note,
                ac_note: filter[0].ac_note,
                mgr_stat_1: filter[0].mgr_stat_1,
                mgr_stat_2: filter[0].mgr_stat_2,
                mgr_stat_3: filter[0].mgr_stat_3,
                mgr_stat_4: filter[0].mgr_stat_4,
                mgr_stat_5: filter[0].mgr_stat_5,
                accountant_paid: filter[0].accountant_paid,
                accountant_mdate: filter[0].accountant_mdate,
                province: filter[0].province,
                city: filter[0].city,
                addressline1: filter[0].addressline1,
                addressline2: filter[0].addressline2,
                postalcode: filter[0].postalcode,
                company_province: filter[0].company_province,
                company_city: filter[0].company_city,
                company_addressline1: filter[0].company_addressline1,
                company_addressline2: filter[0].company_addressline2,
                company_postalcode: filter[0].company_postalcode,
                phone_num: filter[0].phone_num,
                fax_num: filter[0].fax_num,
                ptn: filter[0].ptn,
                projectname: filter[0].projectname,
                endtday: filter[0].endtday,
                gst_total: that.formatPrice(filter[0].gst_total * (this[index].percentage / 100)),
                total: that.formatPrice(filter[0].total * (this[index].percentage / 100)),
                gst_quickpay_total: that.formatPrice(filter[0].gst_quickpay_total * (this[index].percentage / 100)),
                quickpay_total: that.formatPrice(filter[0].quickpay_total * (this[index].percentage / 100)),
                afe: this[index].afenum,
                percentage: this[index].percentage,
                cc1: this[index].ccone_code,
                cc2: this[index].cctwo_code,
                cc3: cc3
              }
              that.customFiltered.push(tempFiltered)
            }, afes);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    sendFiltered() {
      const that = this;
      var filtered = this.$refs.invoicetable.allFilteredData.map(function(i) {
        i.gst_total = (i.total - (i.total/ ((i.tax_percentage/100) + 1)));
        if(i.quickpay_total < i.total) {
          i.gst_quickpay_total = (i.quickpay_total - (i.quickpay_total/ ((i.tax_percentage/100) + 1)));
        }
        else {
          i.quickpay_total = 0;
          i.gst_quickpay_total = 0;
        }
        return i;
      });
      return filtered;
    }
  },
  computed: {
    groupedServices: function() {
      const that = this;
      var groupedServices = this.lodash.chain(this.serviceData)
      .groupBy('vendor_company_name')
      .map((code, name) => ({name, code }))
      .value();
      for (var i = 0; i < groupedServices.length; i++) {
        var groupedByName = this.lodash.chain(groupedServices[i].code)
        .groupBy('name')
        .map((services, name) => ({name, services}))
        .value();
        groupedServices[i].code = groupedByName;
        var subtotal = 0;
        var estimateTotal = 0;
        var innerEstimateTotal = 0;
        for (var x = 0; x < groupedServices[i].code.length; x++) {
          groupedServices[i].code[x].total = groupedServices[i].code[x].services.reduce(function(total, item){
            return total + that.calcLineTotal(item)
          },0);
          subtotal += groupedServices[i].code[x].total;
          var groupedUom = this.lodash.chain(groupedServices[i].code[x].services)
          .groupBy('uom')
          .map((uom, name) => ({name, uom}))
          .value();
          groupedServices[i].code[x].groupedUom = groupedUom;
          for (var y = 0; y < groupedServices[i].code[x].groupedUom.length; y++) {
            groupedServices[i].code[x].groupedUom[y].uom.sort(function(a,b){
              return new Date(b.end_date) - new Date(a.end_date)
            })
            groupedServices[i].code[x].groupedUom[y].latestPu = groupedServices[i].code[x].groupedUom[y].uom[0].pu;
            if(groupedServices[i].code[x].groupedUom[y].uom[0].estimate){
              if(groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1){
                groupedServices[i].code[x].groupedUom[y].estimateQuantity1 = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1;
              }
              if(groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2){
                groupedServices[i].code[x].groupedUom[y].estimateQuantity2 = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2;
              }
              if(groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3){
                groupedServices[i].code[x].groupedUom[y].estimateQuantity3 = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3;
              }
            }
            groupedServices[i].code[x].groupedUom[y].total = groupedServices[i].code[x].groupedUom[y].uom.reduce(function(total, item){
              return total + that.calcLineTotal(item)
            },0);
            groupedServices[i].code[x].groupedUom[y].totalQuantity = groupedServices[i].code[x].groupedUom[y].uom.reduce(function(total, item){
              return total + item.quantity;
            },0);
            var tec = 0;
            var tac = 0;
            var tacPrevious = 0;
            var taq = 0;
            var taqPrevious = 0;
            var condition = true;
            if(groupedServices[i].code[x].groupedUom[y].uom[0].estimate){
              var teq = 0;
              if(y == 0){
                teq = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1;
              }
              if(y == 1){
                teq = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2;
              }
              if(y == 2){
                teq = groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3;
              }
              for (var n = 0; n < groupedServices[i].code[x].groupedUom[y].uom.length; n++) {
                var line = groupedServices[i].code[x].groupedUom[y].uom[n];
                tec = 0;
                var pu = line.pu;
                var disc = line.discount;
                var aq = line.quantity;
                taq += aq;
                var ac = aq * pu * (1-disc / 100);
                tac += ac;
                if(n == 0 && taq > teq && condition){
                  tec = teq * pu;
                  condition = false;
                }
                if(n >= 0 && taq <= teq && condition){
                  tec = tac + (teq - taq) * pu;
                }
                if(n > 0 && (taq > teq) && condition){
                  condition = false;
                  tec = tacPrevious + (teq - taqPrevious) * pu;
                }
                if(!condition){
                  break;
                }
                tacPrevious = tac;
                taqPrevious = taq;
              }
            }
            if(y == 0){
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost1 = tec;
              innerEstimateTotal += tec;
            }
            if(y == 1){
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost2 = tec;
              innerEstimateTotal += tec;
            }
            if(y == 2){
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost3 = tec;
              innerEstimateTotal += tec;
            }
          }
        }
        groupedServices[i].subtotal = subtotal;
        groupedServices[i].estimate = innerEstimateTotal;
      }
      return groupedServices;
    },
    customLabels: function () {
      var custom = {};
      for (var i = 0; i < this.customLabel.length; i++) {
        custom[this.customLabel[i][0]] = {title: this.customLabel[i][1]}
      }
      return custom
    },
    subtotal: function(){
      const that = this;
      return this.checkedRows.reduce(function(total, item){
        var invoice_total = item.total;
        if(item.quickpay_total > 0 && that.$moment(item.endtday) > that.$moment()){
          invoice_total = item.quickpay_total;
        }
        return total + invoice_total
      },0);
    },
    bankaccounts: function(){
      const that = this;
      return this.lodash.uniqBy(this.checkedRows, 'vcn')
    },
    totalActualCost: function() {
      // for (var i = 0; i < groupedServices.length; i++) {
      //   var total = 0;
      //
      //   for (var x = 0; x < groupedServices[i].code.length; x++) {
      //
      //   }
      // }
    },
    totalEstimateTotal: function() {
    }
  },
  mounted() {
    if(this.$session.has('project')){
      this.project = this.$session.get('project');
    }
    if(this.$session.has('qbyte_status')){
      this.qbyte_status = this.$session.get('qbyte_status');
    }
    if(this.$session.has('type')){
      this.type.type = this.$session.get('type');
      this.loadInvoices();
    }
    else {
      this.type = {
        type: 'Ready To Pay'
      }
      this.loadInvoices();
    }
    this.$http.get('manager/get/profile').then(response => {
      this.usersName = response.data.fname + " " + response.data.lname;
    })
    this.$http.get('manager/get/projects').then(response => {
      this.projectOptions = response.data.result;
      this.projectOptions.unshift({
        "projectname": "All Projects"
      })
    });
  }
}
</script>

<style lang="scss">
  .acc-rt-datepicker {
    input {
      width: 100%;
    }
  }
  .acc-table {
    th:first-child,
    td:first-child {
      width: 200px;
    }
    overflow-x: hidden !important;
  }
  .VueTables__pagination-wrapper {
    display: block !important;
  }
  @media screen and (max-width: 767px){
    .acc-table {
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5),
      th:nth-child(6),
      td:nth-child(6),
      th:nth-child(7),
      td:nth-child(7),
      th:nth-child(3),
      td:nth-child(3){
        display: none !important;
      }
      td, th{
        width: 50%;
      }
    }
  }
  #selectAllCheckbox {
    display: block;
    margin: 0 auto;
  }
  .red {
    color: #db1616;
  }
  .green {
    color: #4dbd74;
  }
</style>